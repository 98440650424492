<template>
    <div class="footer footer-desktop  J-social J-w-100 J-flex">
        <div class="marca J-w-70 ">
            <div>
                <img src="/img/GootWorkFooter.png" alt="">
            </div>
        </div>
        <div class=" J-w-30 J-social-content">
            <div class="LineaCostado">

            </div>
            <div class="J-social-container">
                <h1 class="TitleRedSocial">REDES SOCIALES</h1>
                <div class="RedSocial">
                    <img class="IconoRedSocial" src="/img/iconLink.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconFace.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconInst.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconTik.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconTwit.png" alt="">
                </div>
                <div class="J-flex column J-social-link-content">
                    <h3>Contáctanos, podemos ayudarte</h3>
                    <a class="J-social-link" href="mail:51954720526">gootwork7@gmail.com</a>
                    <a class="J-social-link" href="tel:51954720526">+51 954 720 526</a>
                </div>
            </div>
        </div>
    </div>
    <div class="footer footer-mobile  J-social J-w-100 J-flex">
        <div class="marca J-w-75">
            <div>
                <img src="/img/GootWorkFooter.png" alt="">
            </div>
        </div>
        <div class=" J-w-70 J-social-content" style="padding-top:25px">
            <div class="LineaCostado">

            </div>
            <div class="J-social-container">
                <h1 class="TitleRedSocial">REDES SOCIALES</h1>
                <div class="RedSocial">
                    <img class="IconoRedSocial" src="/img/iconLink.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconFace.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconInst.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconTik.png" alt="">
                    <img class="IconoRedSocial" src="/img/iconTwit.png" alt="">
                </div>
                <div class="J-flex column J-social-link-content">
                    <h3>Contáctanos, podemos ayudarte</h3>
                    <a class="J-social-link" href="mail:51954720526">gootwork7@gmail.com</a>
                    <a class="J-social-link" href="tel:51954720526">+51 954 720 526</a>
                </div>
            </div>
        </div>
    </div>
    <div class="origenOlas" style="margin-top:95px">
        <div class="piscina move">
        </div>
        <div class="DerechosReservados">
            <p>@{{yearToday}} - Todos los Derechos Reservados - GootWork SAC</p>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        yearToday: ''
    }),
    mounted() {
        this.yearToday = new Date().getFullYear()
    },
    methods: {
        gowhtasapp(){
            window.open('https://api.whatsapp.com/send?phone=51954720526');
        }
    }

}
</script>
<style scoped>
.footer-mobile{
    display:none
}
@media screen and (max-width: 1024px) {
    .footer-desktop{
        display: none;
    }

    .footer-mobile{
        display: block;
    }
}
</style>